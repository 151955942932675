import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { getReportAllIds } from '@/api/';
import { Btn, Ic } from '@/components/basic/index';
import style from '@/pages/report/Report.scss';
import GoalReportForm from '@/components/page-parts/report-edit/goal/GoalReportForm';

const ReportEditor = ({ studentId, reportId, showExamModalFunc, showRecordModalFunc, close }) => {
  const [allIds, setAllIds] = useState([]);
  const [isAddMode, setIsAddMode] = useState(false);
  const [page, setPage] = useState([0, 0]);
  const [targetIds, setTargetIds] = useState([null, null]);
  const getId = (ids, idx) => (ids[idx] ? ids[idx].id : null);
  const handlePrev = async () => {
    const n = page[0] - 1;
    if (n < 1) return;
    setPage([n, page[1]]);
    setTargetIds([getId(allIds, n - 1), getId(allIds, n - 2)]);
  };
  const handleNext = async () => {
    const n = page[0] + 1;
    if (n > page[1]) return;
    setPage([n, page[1]]);
    setTargetIds([getId(allIds, n - 1), getId(allIds, n - 2)]);
  };
  const shouldBeDisplay = () => {
    if (isAddMode) return true;
    if (allIds.length === 0) return false;
    return true;
  };
  const getAllidsWithSetPage = async () => {
    const ids = await getReportAllIds(studentId);
    setAllIds(ids);
    if (isAddMode) {
      setPage([ids.length, ids.length]);
      setTargetIds([getId(ids, ids.length - 1), getId(ids, ids.length - 2)]);
    } else {
      const targetId = targetIds[0] === null ? reportId : targetIds[0];
      const index = ids.findIndex((r) => r.id === targetId);
      setPage([index + 1, ids.length]);
      setTargetIds([getId(ids, index), getId(ids, index - 1)]);
    }
  };
  const handleDraftStored = async () => {
    await getAllidsWithSetPage();
    setIsAddMode(false);
  };
  const handleSubmitted = async () => {
    await getAllidsWithSetPage();
    setIsAddMode(false);
  };
  const handleBack = async () => {
    close();
    setIsAddMode(false);
  };

  useEffect(async () => {
    if (reportId === null) {
      const ids = await getReportAllIds(studentId);
      setAllIds(ids);
      setTargetIds([null, getId(ids, ids.length - 1)]);
      setIsAddMode(true);
      return;
    }
    await getAllidsWithSetPage();
  }, []);

  useEffect(() => {}, [allIds]);

  return (
    <Container fluid>
      <div className={style.reportEditPanel}>
        {allIds.length !== 0 && !isAddMode ? (
          <Card className={style.header}>
            <Row>
              <Col xs={3} style={{ textAlign: 'right' }}>
                <Btn isCustom className={style.pagerBtn} outline border={false} circle click={handlePrev}>
                  <Ic iName="faAnglesLeft" />
                </Btn>
              </Col>
              <Col xs={6}>
                <Row className={style.title}>
                  <Col>
                    {' '}
                    {page[0]}回 / {page[1]}回{' '}
                  </Col>
                </Row>
              </Col>
              <Col xs={3} style={{ textAlign: 'left' }}>
                <Btn isCustom className={style.pagerBtn} outline border={false} circle click={handleNext}>
                  <Ic iName="faAnglesRight" />
                </Btn>
              </Col>
            </Row>
          </Card>
        ) : (
          <></>
        )}
        {shouldBeDisplay() && (
          <GoalReportForm
            studentId={studentId}
            targetReportId={targetIds[0]}
            prevReportId={targetIds[1]}
            isAddMode={isAddMode}
            onDraftStored={handleDraftStored}
            onSubmitted={handleSubmitted}
            onBack={handleBack}
            showExamModalFunc={showExamModalFunc}
            showRecordModalFunc={showRecordModalFunc}
          />
        )}
      </div>
    </Container>
  );
};

export default ReportEditor;
